@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-cyrillic-ext-400-normal.7b2216d5.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-cyrillic-400-normal.616c2d58.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-greek-ext-400-normal.85911ac3.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-greek-400-normal.c922ce31.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-vietnamese-400-normal.5379dc9b.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-latin-ext-400-normal.62b61da5.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Fira Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("fira-sans-latin-400-normal.4e44348d.woff2") format("woff2"), url("fira-sans-all-400-normal.7bc06849.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.7f444e97.css.map */
